import React from 'react'
import Header from '../../components/Header'

function Acessibilidade (){



    return (
    <>
        <Header/>
    </>
    )
}

export default Acessibilidade